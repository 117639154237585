/* tslint:disable */
/* eslint-disable */
/**
 * STYLY API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddCommentRequest
 */
export interface AddCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof AddCommentRequest
     */
    comment: string;
}
/**
 * 
 * @export
 * @interface AddMylistResponse
 */
export interface AddMylistResponse {
    /**
     * 
     * @type {number}
     * @memberof AddMylistResponse
     */
    count: number;
}
/**
 * 
 * @export
 * @interface CreateSceneRequest
 */
export interface CreateSceneRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSceneRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSceneRequest
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSceneRequest
     */
    blueprint: string;
}
/**
 * 
 * @export
 * @interface CreateTagsRequest
 */
export interface CreateTagsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTagsRequest
     */
    texts: Array<string>;
}
/**
 * 
 * @export
 * @interface EmailChangeWithCodeRequest
 */
export interface EmailChangeWithCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailChangeWithCodeRequest
     */
    code: string;
}
/**
 * 
 * @export
 * @interface FollowCheckResponse
 */
export interface FollowCheckResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FollowCheckResponse
     */
    following: boolean;
}
/**
 * 
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse400
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse400
     */
    message: string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    country?: string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {number}
     * @memberof LoginResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    username: string;
}
/**
 * Full user properties. This object is given to only login user himself/herself. 
 * @export
 * @interface LoginUser
 */
export interface LoginUser {
    /**
     * 
     * @type {number}
     * @memberof LoginUser
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    api_key: string;
    /**
     * 
     * @type {number}
     * @memberof LoginUser
     */
    plan: number;
    /**
     * 
     * @type {UserFeatures}
     * @memberof LoginUser
     */
    features: UserFeatures;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    profile_image_url: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    facebook_id: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    instagram_id: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    twitter_id: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    site_url: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    youtube_channel_url: string;
}
/**
 * 
 * @export
 * @interface PasswordResetRequest
 */
export interface PasswordResetRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    current: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    _new: string;
}
/**
 * 
 * @export
 * @interface PasswordResetWithCodeRequest
 */
export interface PasswordResetWithCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetWithCodeRequest
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetWithCodeRequest
     */
    password: string;
}
/**
 * 
 * @export
 * @interface RefreshApiKeyResponse
 */
export interface RefreshApiKeyResponse {
    /**
     * 
     * @type {string}
     * @memberof RefreshApiKeyResponse
     */
    api_key: string;
}
/**
 * 
 * @export
 * @interface Scene
 */
export interface Scene {
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    id: string;
    /**
     * 
     * @type {User}
     * @memberof Scene
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    thumbnail_tiny_url: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    thumbnail_small_url: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    thumbnail_middle_url: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    thumbnail_large_url: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Scene
     */
    tags: Array<Tag>;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    access_level: number;
    /**
     * 
     * @type {boolean}
     * @memberof Scene
     */
    published: boolean;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    published_at: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    updated_at: string;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    good_count: number;
    /**
     * 
     * @type {boolean}
     * @memberof Scene
     */
    gooded: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Scene
     */
    mylisted: boolean;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    custom_logo_url: string | null;
}
/**
 * 
 * @export
 * @interface SceneAccessTokenCreationRequest
 */
export interface SceneAccessTokenCreationRequest {
    /**
     * 
     * @type {number}
     * @memberof SceneAccessTokenCreationRequest
     */
    expires_in: number;
}
/**
 * 
 * @export
 * @interface SceneAccessTokenCreationResponse
 */
export interface SceneAccessTokenCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof SceneAccessTokenCreationResponse
     */
    token: string;
}
/**
 * 
 * @export
 * @interface SceneComment
 */
export interface SceneComment {
    /**
     * 
     * @type {number}
     * @memberof SceneComment
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SceneComment
     */
    scene_id: string;
    /**
     * 
     * @type {number}
     * @memberof SceneComment
     */
    user_id: number;
    /**
     * 
     * @type {string}
     * @memberof SceneComment
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof SceneComment
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof SceneComment
     */
    updated_at: string;
}
/**
 * 
 * @export
 * @interface SceneCommentList
 */
export interface SceneCommentList {
    /**
     * 
     * @type {Array<SceneComment>}
     * @memberof SceneCommentList
     */
    comments: Array<SceneComment>;
    /**
     * 
     * @type {number}
     * @memberof SceneCommentList
     */
    total: number;
}
/**
 * 
 * @export
 * @interface SceneContent
 */
export interface SceneContent {
    /**
     * 
     * @type {string}
     * @memberof SceneContent
     */
    blueprint: string;
}
/**
 * 
 * @export
 * @interface SceneCreationRequest
 */
export interface SceneCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof SceneCreationRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SceneCreationRequest
     */
    template_scene_id: string;
}
/**
 * 
 * @export
 * @interface SceneCreationResponse
 */
export interface SceneCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof SceneCreationResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface SceneIdSearchRequest
 */
export interface SceneIdSearchRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof SceneIdSearchRequest
     */
    scene_ids: Array<string>;
}
/**
 * 
 * @export
 * @interface SceneList
 */
export interface SceneList {
    /**
     * 
     * @type {Array<SceneListItem>}
     * @memberof SceneList
     */
    scenes: Array<SceneListItem>;
}
/**
 * 
 * @export
 * @interface SceneListItem
 */
export interface SceneListItem {
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    id: string;
    /**
     * 
     * @type {User}
     * @memberof SceneListItem
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    thumbnail_tiny_url: string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    thumbnail_small_url: string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    thumbnail_middle_url: string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    thumbnail_large_url: string;
    /**
     * 
     * @type {number}
     * @memberof SceneListItem
     */
    access_level: number;
    /**
     * 
     * @type {boolean}
     * @memberof SceneListItem
     */
    published: boolean;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    published_at: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SceneListItem
     */
    gooded: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SceneListItem
     */
    mylisted: boolean;
}
/**
 * 
 * @export
 * @interface ScenePublishInfo
 */
export interface ScenePublishInfo {
    /**
     * 
     * @type {string}
     * @memberof ScenePublishInfo
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ScenePublishInfo
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScenePublishInfo
     */
    tag_ids: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ScenePublishInfo
     */
    access_level: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ScenePublishInfo
     */
    access_user_ids: Array<number>;
    /**
     * PNG Image data to register as a custom logo of the scene. Base64 encoded 512 x 512 px.
     * @type {string}
     * @memberof ScenePublishInfo
     */
    custom_logo_data?: string;
    /**
     * URL of registered PNG logo file
     * @type {string}
     * @memberof ScenePublishInfo
     */
    custom_logo_url?: string;
}
/**
 * 
 * @export
 * @interface SceneSearchResult
 */
export interface SceneSearchResult {
    /**
     * 
     * @type {Array<SceneListItem>}
     * @memberof SceneSearchResult
     */
    scenes: Array<SceneListItem>;
    /**
     * 
     * @type {number}
     * @memberof SceneSearchResult
     */
    total: number;
}
/**
 * 
 * @export
 * @interface SceneTranslationResult
 */
export interface SceneTranslationResult {
    /**
     * 
     * @type {TranslationResult}
     * @memberof SceneTranslationResult
     */
    title: TranslationResult;
    /**
     * 
     * @type {TranslationResult}
     * @memberof SceneTranslationResult
     */
    description: TranslationResult;
}
/**
 * 
 * @export
 * @interface SendEmailChangeEmailRequest
 */
export interface SendEmailChangeEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof SendEmailChangeEmailRequest
     */
    email: string;
}
/**
 * 
 * @export
 * @interface SendPasswordResetEmailRequest
 */
export interface SendPasswordResetEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof SendPasswordResetEmailRequest
     */
    email: string;
}
/**
 * 
 * @export
 * @interface SignupRequest
 */
export interface SignupRequest {
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    country?: string;
}
/**
 * 
 * @export
 * @interface SignupResponse
 */
export interface SignupResponse {
    /**
     * 
     * @type {number}
     * @memberof SignupResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SignupResponse
     */
    username: string;
}
/**
 * 
 * @export
 * @interface SuccessResponse
 */
export interface SuccessResponse {
    /**
     * 
     * @type {string}
     * @memberof SuccessResponse
     */
    message: string;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    text: string;
}
/**
 * 
 * @export
 * @interface TagList
 */
export interface TagList {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof TagList
     */
    tags: Array<Tag>;
}
/**
 * 
 * @export
 * @interface TranslationResult
 */
export interface TranslationResult {
    /**
     * 
     * @type {string}
     * @memberof TranslationResult
     */
    source_language: string;
    /**
     * 
     * @type {string}
     * @memberof TranslationResult
     */
    text: string;
}
/**
 * 
 * @export
 * @interface UpdateSceneRequest
 */
export interface UpdateSceneRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSceneRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSceneRequest
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSceneRequest
     */
    blueprint: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    profile_image_url: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    facebook_id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    instagram_id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    twitter_id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    site_url: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    youtube_channel_url: string;
}
/**
 * User UI features.
 * @export
 * @interface UserFeatures
 */
export interface UserFeatures {
    /**
     * 
     * @type {boolean}
     * @memberof UserFeatures
     */
    can_change_plan: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserFeatures
     */
    can_manage_locations: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserFeatures
     */
    mobile_skip_top: boolean;
}
/**
 * 
 * @export
 * @interface UserList
 */
export interface UserList {
    /**
     * 
     * @type {Array<User>}
     * @memberof UserList
     */
    users: Array<User>;
}
/**
 * 
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    facebook_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    instagram_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    twitter_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    site_url?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    youtube_channel_url?: string;
}
/**
 * 
 * @export
 * @interface UserSearchResult
 */
export interface UserSearchResult {
    /**
     * 
     * @type {Array<User>}
     * @memberof UserSearchResult
     */
    users: Array<User>;
    /**
     * 
     * @type {number}
     * @memberof UserSearchResult
     */
    total: number;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginRequest} loginRequest Payload to Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('login', 'loginRequest', loginRequest)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignupRequest} signupRequest Payload to Signup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup: async (signupRequest: SignupRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'signupRequest' is not null or undefined
            assertParamExists('signup', 'signupRequest', signupRequest)
            const localVarPath = `/auth/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LoginRequest} loginRequest Payload to Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SignupRequest} signupRequest Payload to Signup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signup(signupRequest: SignupRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signup(signupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {LoginRequest} loginRequest Payload to Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SignupRequest} signupRequest Payload to Signup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(signupRequest: SignupRequest, options?: any): AxiosPromise<SignupResponse> {
            return localVarFp.signup(signupRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {LoginRequest} loginRequest Payload to Login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(loginRequest: LoginRequest, options?: any) {
        return AuthApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logout(options?: any) {
        return AuthApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SignupRequest} signupRequest Payload to Signup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public signup(signupRequest: SignupRequest, options?: any) {
        return AuthApiFp(this.configuration).signup(signupRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MylistApi - axios parameter creator
 * @export
 */
export const MylistApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete MyList
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (sceneId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('_delete', 'sceneId', sceneId)
            const localVarPath = `/user/mylist/default/{scene_id}`
                .replace(`{${"scene_id"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add MyList
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add: async (sceneId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('add', 'sceneId', sceneId)
            const localVarPath = `/user/mylist/default/{scene_id}`
                .replace(`{${"scene_id"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets mylisted scenes of a login user
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMylistedScenes: async (limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/mylist/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MylistApi - functional programming interface
 * @export
 */
export const MylistApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MylistApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete MyList
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(sceneId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(sceneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add MyList
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async add(sceneId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddMylistResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.add(sceneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets mylisted scenes of a login user
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMylistedScenes(limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMylistedScenes(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MylistApi - factory interface
 * @export
 */
export const MylistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MylistApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete MyList
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(sceneId: string, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp._delete(sceneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add MyList
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add(sceneId: string, options?: any): AxiosPromise<AddMylistResponse> {
            return localVarFp.add(sceneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets mylisted scenes of a login user
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMylistedScenes(limit?: number, offset?: number, options?: any): AxiosPromise<SceneSearchResult> {
            return localVarFp.getMylistedScenes(limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MylistApi - object-oriented interface
 * @export
 * @class MylistApi
 * @extends {BaseAPI}
 */
export class MylistApi extends BaseAPI {
    /**
     * 
     * @summary Delete MyList
     * @param {string} sceneId Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MylistApi
     */
    public _delete(sceneId: string, options?: any) {
        return MylistApiFp(this.configuration)._delete(sceneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add MyList
     * @param {string} sceneId Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MylistApi
     */
    public add(sceneId: string, options?: any) {
        return MylistApiFp(this.configuration).add(sceneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets mylisted scenes of a login user
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MylistApi
     */
    public getMylistedScenes(limit?: number, offset?: number, options?: any) {
        return MylistApiFp(this.configuration).getMylistedScenes(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SceneApi - axios parameter creator
 * @export
 */
export const SceneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Make a copy of a scene
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyMyScene: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copyMyScene', 'id', id)
            const localVarPath = `/scene/scene/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Scene
         * @param {CreateSceneRequest} createSceneRequest Payload to create for Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createSceneRequest: CreateSceneRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSceneRequest' is not null or undefined
            assertParamExists('create', 'createSceneRequest', createSceneRequest)
            const localVarPath = `/scene`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSceneRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a scene from template.
         * @param {SceneCreationRequest} sceneCreationRequest Payload to create a scene with a template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewSceneFromTemplate: async (sceneCreationRequest: SceneCreationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneCreationRequest' is not null or undefined
            assertParamExists('createNewSceneFromTemplate', 'sceneCreationRequest', sceneCreationRequest)
            const localVarPath = `/scene/scene/new_from_template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sceneCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a scene.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScene: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteScene', 'id', id)
            const localVarPath = `/scene/scene/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets scenes of a login user
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyScenes: async (query?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/scene/my_scenes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets info (meta) of a scene for publish.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishInfo: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPublishInfo', 'id', id)
            const localVarPath = `/scene/scene/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScene: async (id: string, accessToken?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScene', 'id', id)
            const localVarPath = `/scene/scene/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessToken !== undefined) {
                localVarQueryParameter['access_token'] = accessToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets xml of a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSceneContent: async (id: string, accessToken?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSceneContent', 'id', id)
            const localVarPath = `/scene/scene/{id}/content`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessToken !== undefined) {
                localVarQueryParameter['access_token'] = accessToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets translated meta results.
         * @param {string} lang language
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSceneTranslation: async (lang: string, id: string, accessToken?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getSceneTranslation', 'lang', lang)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSceneTranslation', 'id', id)
            const localVarPath = `/scene/scene/{id}/translated`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['access_token'] = accessToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets scenes by IDs
         * @param {SceneIdSearchRequest} sceneIdSearchRequest Payload having scene IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenesByIds: async (sceneIdSearchRequest: SceneIdSearchRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneIdSearchRequest' is not null or undefined
            assertParamExists('getScenesByIds', 'sceneIdSearchRequest', sceneIdSearchRequest)
            const localVarPath = `/scene/id_search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sceneIdSearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets by user ID
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenesByUserId: async (id: number, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScenesByUserId', 'id', id)
            const localVarPath = `/scene/user/{id}/scenes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publish a scene
         * @param {string} id Scene ID
         * @param {ScenePublishInfo} scenePublishInfo Payload to update scene with.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish: async (id: string, scenePublishInfo: ScenePublishInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publish', 'id', id)
            // verify required parameter 'scenePublishInfo' is not null or undefined
            assertParamExists('publish', 'scenePublishInfo', scenePublishInfo)
            const localVarPath = `/scene/scene/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scenePublishInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches scenes
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (query?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/scene/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Scene
         * @param {string} id Scene ID
         * @param {UpdateSceneRequest} updateSceneRequest Payload to update for Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateSceneRequest: UpdateSceneRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateSceneRequest' is not null or undefined
            assertParamExists('update', 'updateSceneRequest', updateSceneRequest)
            const localVarPath = `/scene/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSceneRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SceneApi - functional programming interface
 * @export
 */
export const SceneApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SceneApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Make a copy of a scene
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyMyScene(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyMyScene(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Scene
         * @param {CreateSceneRequest} createSceneRequest Payload to create for Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createSceneRequest: CreateSceneRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createSceneRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a scene from template.
         * @param {SceneCreationRequest} sceneCreationRequest Payload to create a scene with a template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewSceneFromTemplate(sceneCreationRequest: SceneCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewSceneFromTemplate(sceneCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a scene.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScene(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScene(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets scenes of a login user
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyScenes(query?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyScenes(query, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets info (meta) of a scene for publish.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishInfo(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenePublishInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScene(id: string, accessToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScene(id, accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets xml of a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSceneContent(id: string, accessToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSceneContent(id, accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets translated meta results.
         * @param {string} lang language
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSceneTranslation(lang: string, id: string, accessToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneTranslationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSceneTranslation(lang, id, accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets scenes by IDs
         * @param {SceneIdSearchRequest} sceneIdSearchRequest Payload having scene IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenesByIds(sceneIdSearchRequest: SceneIdSearchRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenesByIds(sceneIdSearchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets by user ID
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenesByUserId(id: number, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenesByUserId(id, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Publish a scene
         * @param {string} id Scene ID
         * @param {ScenePublishInfo} scenePublishInfo Payload to update scene with.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publish(id: string, scenePublishInfo: ScenePublishInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publish(id, scenePublishInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches scenes
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(query?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(query, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Scene
         * @param {string} id Scene ID
         * @param {UpdateSceneRequest} updateSceneRequest Payload to update for Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateSceneRequest: UpdateSceneRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateSceneRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SceneApi - factory interface
 * @export
 */
export const SceneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SceneApiFp(configuration)
    return {
        /**
         * 
         * @summary Make a copy of a scene
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyMyScene(id: string, options?: any): AxiosPromise<SceneCreationResponse> {
            return localVarFp.copyMyScene(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Scene
         * @param {CreateSceneRequest} createSceneRequest Payload to create for Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createSceneRequest: CreateSceneRequest, options?: any): AxiosPromise<Scene> {
            return localVarFp.create(createSceneRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a scene from template.
         * @param {SceneCreationRequest} sceneCreationRequest Payload to create a scene with a template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewSceneFromTemplate(sceneCreationRequest: SceneCreationRequest, options?: any): AxiosPromise<SceneCreationResponse> {
            return localVarFp.createNewSceneFromTemplate(sceneCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a scene.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScene(id: string, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.deleteScene(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets scenes of a login user
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyScenes(query?: string, limit?: number, offset?: number, options?: any): AxiosPromise<SceneSearchResult> {
            return localVarFp.getMyScenes(query, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets info (meta) of a scene for publish.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishInfo(id: string, options?: any): AxiosPromise<ScenePublishInfo> {
            return localVarFp.getPublishInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScene(id: string, accessToken?: string, options?: any): AxiosPromise<Scene> {
            return localVarFp.getScene(id, accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets xml of a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSceneContent(id: string, accessToken?: string, options?: any): AxiosPromise<SceneContent> {
            return localVarFp.getSceneContent(id, accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets translated meta results.
         * @param {string} lang language
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSceneTranslation(lang: string, id: string, accessToken?: string, options?: any): AxiosPromise<SceneTranslationResult> {
            return localVarFp.getSceneTranslation(lang, id, accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets scenes by IDs
         * @param {SceneIdSearchRequest} sceneIdSearchRequest Payload having scene IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenesByIds(sceneIdSearchRequest: SceneIdSearchRequest, options?: any): AxiosPromise<SceneList> {
            return localVarFp.getScenesByIds(sceneIdSearchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets by user ID
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenesByUserId(id: number, limit?: number, offset?: number, options?: any): AxiosPromise<SceneSearchResult> {
            return localVarFp.getScenesByUserId(id, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publish a scene
         * @param {string} id Scene ID
         * @param {ScenePublishInfo} scenePublishInfo Payload to update scene with.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish(id: string, scenePublishInfo: ScenePublishInfo, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.publish(id, scenePublishInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches scenes
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(query?: string, limit?: number, offset?: number, options?: any): AxiosPromise<SceneSearchResult> {
            return localVarFp.search(query, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Scene
         * @param {string} id Scene ID
         * @param {UpdateSceneRequest} updateSceneRequest Payload to update for Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateSceneRequest: UpdateSceneRequest, options?: any): AxiosPromise<Scene> {
            return localVarFp.update(id, updateSceneRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SceneApi - object-oriented interface
 * @export
 * @class SceneApi
 * @extends {BaseAPI}
 */
export class SceneApi extends BaseAPI {
    /**
     * 
     * @summary Make a copy of a scene
     * @param {string} id Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public copyMyScene(id: string, options?: any) {
        return SceneApiFp(this.configuration).copyMyScene(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Scene
     * @param {CreateSceneRequest} createSceneRequest Payload to create for Scene.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public create(createSceneRequest: CreateSceneRequest, options?: any) {
        return SceneApiFp(this.configuration).create(createSceneRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a scene from template.
     * @param {SceneCreationRequest} sceneCreationRequest Payload to create a scene with a template.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public createNewSceneFromTemplate(sceneCreationRequest: SceneCreationRequest, options?: any) {
        return SceneApiFp(this.configuration).createNewSceneFromTemplate(sceneCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a scene.
     * @param {string} id Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public deleteScene(id: string, options?: any) {
        return SceneApiFp(this.configuration).deleteScene(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets scenes of a login user
     * @param {string} [query] Query to search scenes.
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getMyScenes(query?: string, limit?: number, offset?: number, options?: any) {
        return SceneApiFp(this.configuration).getMyScenes(query, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets info (meta) of a scene for publish.
     * @param {string} id Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getPublishInfo(id: string, options?: any) {
        return SceneApiFp(this.configuration).getPublishInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a scene.
     * @param {string} id Scene ID
     * @param {string} [accessToken] Time limited JWT token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getScene(id: string, accessToken?: string, options?: any) {
        return SceneApiFp(this.configuration).getScene(id, accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets xml of a scene.
     * @param {string} id Scene ID
     * @param {string} [accessToken] Time limited JWT token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getSceneContent(id: string, accessToken?: string, options?: any) {
        return SceneApiFp(this.configuration).getSceneContent(id, accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets translated meta results.
     * @param {string} lang language
     * @param {string} id Scene ID
     * @param {string} [accessToken] Time limited JWT token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getSceneTranslation(lang: string, id: string, accessToken?: string, options?: any) {
        return SceneApiFp(this.configuration).getSceneTranslation(lang, id, accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets scenes by IDs
     * @param {SceneIdSearchRequest} sceneIdSearchRequest Payload having scene IDs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getScenesByIds(sceneIdSearchRequest: SceneIdSearchRequest, options?: any) {
        return SceneApiFp(this.configuration).getScenesByIds(sceneIdSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets by user ID
     * @param {number} id User ID
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getScenesByUserId(id: number, limit?: number, offset?: number, options?: any) {
        return SceneApiFp(this.configuration).getScenesByUserId(id, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publish a scene
     * @param {string} id Scene ID
     * @param {ScenePublishInfo} scenePublishInfo Payload to update scene with.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public publish(id: string, scenePublishInfo: ScenePublishInfo, options?: any) {
        return SceneApiFp(this.configuration).publish(id, scenePublishInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches scenes
     * @param {string} [query] Query to search scenes.
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public search(query?: string, limit?: number, offset?: number, options?: any) {
        return SceneApiFp(this.configuration).search(query, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Scene
     * @param {string} id Scene ID
     * @param {UpdateSceneRequest} updateSceneRequest Payload to update for Scene.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public update(id: string, updateSceneRequest: UpdateSceneRequest, options?: any) {
        return SceneApiFp(this.configuration).update(id, updateSceneRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SceneCommentApi - axios parameter creator
 * @export
 */
export const SceneCommentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Comment to  Scene
         * @param {string} sceneId Scene ID
         * @param {AddCommentRequest} addCommentRequest Payload to comment to Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment: async (sceneId: string, addCommentRequest: AddCommentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('addComment', 'sceneId', sceneId)
            // verify required parameter 'addCommentRequest' is not null or undefined
            assertParamExists('addComment', 'addCommentRequest', addCommentRequest)
            const localVarPath = `/scene/{scene_id}/comment`
                .replace(`{${"scene_id"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Comment
         * @param {string} sceneId Scene ID
         * @param {number} commentId Comment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment: async (sceneId: string, commentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('deleteComment', 'sceneId', sceneId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteComment', 'commentId', commentId)
            const localVarPath = `/scene/{scene_id}/comment/{comment_id}`
                .replace(`{${"scene_id"}}`, encodeURIComponent(String(sceneId)))
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get comments
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments: async (sceneId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('getComments', 'sceneId', sceneId)
            const localVarPath = `/scene/{scene_id}/comment`
                .replace(`{${"scene_id"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SceneCommentApi - functional programming interface
 * @export
 */
export const SceneCommentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SceneCommentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Comment to  Scene
         * @param {string} sceneId Scene ID
         * @param {AddCommentRequest} addCommentRequest Payload to comment to Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addComment(sceneId: string, addCommentRequest: AddCommentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addComment(sceneId, addCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Comment
         * @param {string} sceneId Scene ID
         * @param {number} commentId Comment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComment(sceneId: string, commentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneCommentList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComment(sceneId, commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get comments
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComments(sceneId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneCommentList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComments(sceneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SceneCommentApi - factory interface
 * @export
 */
export const SceneCommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SceneCommentApiFp(configuration)
    return {
        /**
         * 
         * @summary Comment to  Scene
         * @param {string} sceneId Scene ID
         * @param {AddCommentRequest} addCommentRequest Payload to comment to Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment(sceneId: string, addCommentRequest: AddCommentRequest, options?: any): AxiosPromise<SceneComment> {
            return localVarFp.addComment(sceneId, addCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Comment
         * @param {string} sceneId Scene ID
         * @param {number} commentId Comment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(sceneId: string, commentId: number, options?: any): AxiosPromise<SceneCommentList> {
            return localVarFp.deleteComment(sceneId, commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get comments
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments(sceneId: string, options?: any): AxiosPromise<SceneCommentList> {
            return localVarFp.getComments(sceneId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SceneCommentApi - object-oriented interface
 * @export
 * @class SceneCommentApi
 * @extends {BaseAPI}
 */
export class SceneCommentApi extends BaseAPI {
    /**
     * 
     * @summary Comment to  Scene
     * @param {string} sceneId Scene ID
     * @param {AddCommentRequest} addCommentRequest Payload to comment to Scene.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneCommentApi
     */
    public addComment(sceneId: string, addCommentRequest: AddCommentRequest, options?: any) {
        return SceneCommentApiFp(this.configuration).addComment(sceneId, addCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Comment
     * @param {string} sceneId Scene ID
     * @param {number} commentId Comment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneCommentApi
     */
    public deleteComment(sceneId: string, commentId: number, options?: any) {
        return SceneCommentApiFp(this.configuration).deleteComment(sceneId, commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get comments
     * @param {string} sceneId Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneCommentApi
     */
    public getComments(sceneId: string, options?: any) {
        return SceneCommentApiFp(this.configuration).getComments(sceneId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SceneGoodApi - axios parameter creator
 * @export
 */
export const SceneGoodApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Good
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGood: async (sceneId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('addGood', 'sceneId', sceneId)
            const localVarPath = `/user/good/scene/{scene_id}`
                .replace(`{${"scene_id"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Good
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGood: async (sceneId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('deleteGood', 'sceneId', sceneId)
            const localVarPath = `/user/good/scene/{scene_id}`
                .replace(`{${"scene_id"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SceneGoodApi - functional programming interface
 * @export
 */
export const SceneGoodApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SceneGoodApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Good
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addGood(sceneId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addGood(sceneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Good
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGood(sceneId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGood(sceneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SceneGoodApi - factory interface
 * @export
 */
export const SceneGoodApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SceneGoodApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Good
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGood(sceneId: string, options?: any): AxiosPromise<Scene> {
            return localVarFp.addGood(sceneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Good
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGood(sceneId: string, options?: any): AxiosPromise<Scene> {
            return localVarFp.deleteGood(sceneId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SceneGoodApi - object-oriented interface
 * @export
 * @class SceneGoodApi
 * @extends {BaseAPI}
 */
export class SceneGoodApi extends BaseAPI {
    /**
     * 
     * @summary Add Good
     * @param {string} sceneId Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneGoodApi
     */
    public addGood(sceneId: string, options?: any) {
        return SceneGoodApiFp(this.configuration).addGood(sceneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Good
     * @param {string} sceneId Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneGoodApi
     */
    public deleteGood(sceneId: string, options?: any) {
        return SceneGoodApiFp(this.configuration).deleteGood(sceneId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TagApi - axios parameter creator
 * @export
 */
export const TagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates tags with texts
         * @param {CreateTagsRequest} createTagsRequest Payload including texts to creat tags.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTags: async (createTagsRequest: CreateTagsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTagsRequest' is not null or undefined
            assertParamExists('createTags', 'createTagsRequest', createTagsRequest)
            const localVarPath = `/tag/tag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTagsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets tags by IDs
         * @param {Array<string>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsByIds: async (ids: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('getTagsByIds', 'ids', ids)
            const localVarPath = `/tag/id_search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagApi - functional programming interface
 * @export
 */
export const TagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates tags with texts
         * @param {CreateTagsRequest} createTagsRequest Payload including texts to creat tags.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTags(createTagsRequest: CreateTagsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTags(createTagsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets tags by IDs
         * @param {Array<string>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagsByIds(ids: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagsByIds(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagApi - factory interface
 * @export
 */
export const TagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates tags with texts
         * @param {CreateTagsRequest} createTagsRequest Payload including texts to creat tags.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTags(createTagsRequest: CreateTagsRequest, options?: any): AxiosPromise<TagList> {
            return localVarFp.createTags(createTagsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets tags by IDs
         * @param {Array<string>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsByIds(ids: Array<string>, options?: any): AxiosPromise<TagList> {
            return localVarFp.getTagsByIds(ids, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagApi - object-oriented interface
 * @export
 * @class TagApi
 * @extends {BaseAPI}
 */
export class TagApi extends BaseAPI {
    /**
     * 
     * @summary Creates tags with texts
     * @param {CreateTagsRequest} createTagsRequest Payload including texts to creat tags.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public createTags(createTagsRequest: CreateTagsRequest, options?: any) {
        return TagApiFp(this.configuration).createTags(createTagsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets tags by IDs
     * @param {Array<string>} ids Comma separated IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public getTagsByIds(ids: Array<string>, options?: any) {
        return TagApiFp(this.configuration).getTagsByIds(ids, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TokenApi - axios parameter creator
 * @export
 */
export const TokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates Scene access token
         * @param {string} id Scene ID
         * @param {string} authorization API Key with Bearer keyword.
         * @param {SceneAccessTokenCreationRequest} [sceneAccessTokenCreationRequest] Payload including options to create token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSceneAccessToken: async (id: string, authorization: string, sceneAccessTokenCreationRequest?: SceneAccessTokenCreationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createSceneAccessToken', 'id', id)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('createSceneAccessToken', 'authorization', authorization)
            const localVarPath = `/token/scene/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sceneAccessTokenCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenApi - functional programming interface
 * @export
 */
export const TokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates Scene access token
         * @param {string} id Scene ID
         * @param {string} authorization API Key with Bearer keyword.
         * @param {SceneAccessTokenCreationRequest} [sceneAccessTokenCreationRequest] Payload including options to create token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSceneAccessToken(id: string, authorization: string, sceneAccessTokenCreationRequest?: SceneAccessTokenCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneAccessTokenCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSceneAccessToken(id, authorization, sceneAccessTokenCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TokenApi - factory interface
 * @export
 */
export const TokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokenApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates Scene access token
         * @param {string} id Scene ID
         * @param {string} authorization API Key with Bearer keyword.
         * @param {SceneAccessTokenCreationRequest} [sceneAccessTokenCreationRequest] Payload including options to create token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSceneAccessToken(id: string, authorization: string, sceneAccessTokenCreationRequest?: SceneAccessTokenCreationRequest, options?: any): AxiosPromise<SceneAccessTokenCreationResponse> {
            return localVarFp.createSceneAccessToken(id, authorization, sceneAccessTokenCreationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
export class TokenApi extends BaseAPI {
    /**
     * 
     * @summary Creates Scene access token
     * @param {string} id Scene ID
     * @param {string} authorization API Key with Bearer keyword.
     * @param {SceneAccessTokenCreationRequest} [sceneAccessTokenCreationRequest] Payload including options to create token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public createSceneAccessToken(id: string, authorization: string, sceneAccessTokenCreationRequest?: SceneAccessTokenCreationRequest, options?: any) {
        return TokenApiFp(this.configuration).createSceneAccessToken(id, authorization, sceneAccessTokenCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Changes account email with code.
         * @param {EmailChangeWithCodeRequest} emailChangeWithCodeRequest Payload with code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmailWithCode: async (emailChangeWithCodeRequest: EmailChangeWithCodeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailChangeWithCodeRequest' is not null or undefined
            assertParamExists('changeEmailWithCode', 'emailChangeWithCodeRequest', emailChangeWithCodeRequest)
            const localVarPath = `/user/change_email_with_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailChangeWithCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check following or not.
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkFollowing: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkFollowing', 'id', id)
            const localVarPath = `/user/user/{id}/following`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Follows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        follow: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('follow', 'id', id)
            const localVarPath = `/user/user/{id}/follow`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets followees of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowees: async (id: number, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFollowees', 'id', id)
            const localVarPath = `/user/user/{id}/followees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets followers of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowers: async (id: number, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFollowers', 'id', id)
            const localVarPath = `/user/user/{id}/followers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a login user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/user/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets users by IDs
         * @param {Array<number>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByIds: async (ids: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('getUsersByIds', 'ids', ids)
            const localVarPath = `/user/id_search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refreshs API key of a login user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshApiKey: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/refresh_api_key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resets login user\'s password
         * @param {PasswordResetRequest} passwordResetRequest Payload with current and new passwords.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (passwordResetRequest: PasswordResetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetRequest' is not null or undefined
            assertParamExists('resetPassword', 'passwordResetRequest', passwordResetRequest)
            const localVarPath = `/user/reset_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resets password with validation code
         * @param {PasswordResetWithCodeRequest} passwordResetWithCodeRequest Payload with validation code and new password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordWithCode: async (passwordResetWithCodeRequest: PasswordResetWithCodeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetWithCodeRequest' is not null or undefined
            assertParamExists('resetPasswordWithCode', 'passwordResetWithCodeRequest', passwordResetWithCodeRequest)
            const localVarPath = `/user/reset_password_with_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetWithCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends email change verification email.
         * @param {SendEmailChangeEmailRequest} sendEmailChangeEmailRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailChangeEmail: async (sendEmailChangeEmailRequest: SendEmailChangeEmailRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendEmailChangeEmailRequest' is not null or undefined
            assertParamExists('sendEmailChangeEmail', 'sendEmailChangeEmailRequest', sendEmailChangeEmailRequest)
            const localVarPath = `/user/send_email_change_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendEmailChangeEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends password reset email.
         * @param {SendPasswordResetEmailRequest} sendPasswordResetEmailRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordResetEmail: async (sendPasswordResetEmailRequest: SendPasswordResetEmailRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendPasswordResetEmailRequest' is not null or undefined
            assertParamExists('sendPasswordResetEmail', 'sendPasswordResetEmailRequest', sendPasswordResetEmailRequest)
            const localVarPath = `/user/send_password_reset_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendPasswordResetEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfollows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollow: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unfollow', 'id', id)
            const localVarPath = `/user/user/{id}/follow`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates profile of a user
         * @param {UserProfile} userProfile Profile object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (userProfile: UserProfile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfile' is not null or undefined
            assertParamExists('updateProfile', 'userProfile', userProfile)
            const localVarPath = `/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Changes account email with code.
         * @param {EmailChangeWithCodeRequest} emailChangeWithCodeRequest Payload with code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeEmailWithCode(emailChangeWithCodeRequest: EmailChangeWithCodeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeEmailWithCode(emailChangeWithCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check following or not.
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkFollowing(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FollowCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkFollowing(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Follows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async follow(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.follow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets followees of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFollowees(id: number, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFollowees(id, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets followers of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFollowers(id: number, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFollowers(id, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a login user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets users by IDs
         * @param {Array<number>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersByIds(ids: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersByIds(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refreshs API key of a login user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshApiKey(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshApiKeyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshApiKey(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resets login user\'s password
         * @param {PasswordResetRequest} passwordResetRequest Payload with current and new passwords.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(passwordResetRequest: PasswordResetRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(passwordResetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resets password with validation code
         * @param {PasswordResetWithCodeRequest} passwordResetWithCodeRequest Payload with validation code and new password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordWithCode(passwordResetWithCodeRequest: PasswordResetWithCodeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordWithCode(passwordResetWithCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends email change verification email.
         * @param {SendEmailChangeEmailRequest} sendEmailChangeEmailRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailChangeEmail(sendEmailChangeEmailRequest: SendEmailChangeEmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailChangeEmail(sendEmailChangeEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends password reset email.
         * @param {SendPasswordResetEmailRequest} sendPasswordResetEmailRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPasswordResetEmail(sendPasswordResetEmailRequest: SendPasswordResetEmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendPasswordResetEmail(sendPasswordResetEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unfollows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unfollow(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unfollow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates profile of a user
         * @param {UserProfile} userProfile Profile object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(userProfile: UserProfile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(userProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Changes account email with code.
         * @param {EmailChangeWithCodeRequest} emailChangeWithCodeRequest Payload with code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmailWithCode(emailChangeWithCodeRequest: EmailChangeWithCodeRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.changeEmailWithCode(emailChangeWithCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check following or not.
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkFollowing(id: number, options?: any): AxiosPromise<FollowCheckResponse> {
            return localVarFp.checkFollowing(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Follows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        follow(id: number, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.follow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets followees of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowees(id: number, limit?: number, offset?: number, options?: any): AxiosPromise<UserSearchResult> {
            return localVarFp.getFollowees(id, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets followers of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowers(id: number, limit?: number, offset?: number, options?: any): AxiosPromise<UserSearchResult> {
            return localVarFp.getFollowers(id, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a login user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options?: any): AxiosPromise<LoginUser> {
            return localVarFp.getMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: number, options?: any): AxiosPromise<User> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets users by IDs
         * @param {Array<number>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByIds(ids: Array<number>, options?: any): AxiosPromise<UserList> {
            return localVarFp.getUsersByIds(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refreshs API key of a login user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshApiKey(options?: any): AxiosPromise<RefreshApiKeyResponse> {
            return localVarFp.refreshApiKey(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resets login user\'s password
         * @param {PasswordResetRequest} passwordResetRequest Payload with current and new passwords.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(passwordResetRequest: PasswordResetRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.resetPassword(passwordResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resets password with validation code
         * @param {PasswordResetWithCodeRequest} passwordResetWithCodeRequest Payload with validation code and new password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordWithCode(passwordResetWithCodeRequest: PasswordResetWithCodeRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.resetPasswordWithCode(passwordResetWithCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends email change verification email.
         * @param {SendEmailChangeEmailRequest} sendEmailChangeEmailRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailChangeEmail(sendEmailChangeEmailRequest: SendEmailChangeEmailRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.sendEmailChangeEmail(sendEmailChangeEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends password reset email.
         * @param {SendPasswordResetEmailRequest} sendPasswordResetEmailRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordResetEmail(sendPasswordResetEmailRequest: SendPasswordResetEmailRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.sendPasswordResetEmail(sendPasswordResetEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unfollows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollow(id: number, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.unfollow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates profile of a user
         * @param {UserProfile} userProfile Profile object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(userProfile: UserProfile, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.updateProfile(userProfile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Changes account email with code.
     * @param {EmailChangeWithCodeRequest} emailChangeWithCodeRequest Payload with code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public changeEmailWithCode(emailChangeWithCodeRequest: EmailChangeWithCodeRequest, options?: any) {
        return UserApiFp(this.configuration).changeEmailWithCode(emailChangeWithCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check following or not.
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public checkFollowing(id: number, options?: any) {
        return UserApiFp(this.configuration).checkFollowing(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Follows a user
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public follow(id: number, options?: any) {
        return UserApiFp(this.configuration).follow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets followees of a login user
     * @param {number} id User ID
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getFollowees(id: number, limit?: number, offset?: number, options?: any) {
        return UserApiFp(this.configuration).getFollowees(id, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets followers of a login user
     * @param {number} id User ID
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getFollowers(id: number, limit?: number, offset?: number, options?: any) {
        return UserApiFp(this.configuration).getFollowers(id, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a login user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getMe(options?: any) {
        return UserApiFp(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets user
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(id: number, options?: any) {
        return UserApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets users by IDs
     * @param {Array<number>} ids Comma separated IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsersByIds(ids: Array<number>, options?: any) {
        return UserApiFp(this.configuration).getUsersByIds(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refreshs API key of a login user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public refreshApiKey(options?: any) {
        return UserApiFp(this.configuration).refreshApiKey(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resets login user\'s password
     * @param {PasswordResetRequest} passwordResetRequest Payload with current and new passwords.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public resetPassword(passwordResetRequest: PasswordResetRequest, options?: any) {
        return UserApiFp(this.configuration).resetPassword(passwordResetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resets password with validation code
     * @param {PasswordResetWithCodeRequest} passwordResetWithCodeRequest Payload with validation code and new password.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public resetPasswordWithCode(passwordResetWithCodeRequest: PasswordResetWithCodeRequest, options?: any) {
        return UserApiFp(this.configuration).resetPasswordWithCode(passwordResetWithCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends email change verification email.
     * @param {SendEmailChangeEmailRequest} sendEmailChangeEmailRequest Payload with email.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public sendEmailChangeEmail(sendEmailChangeEmailRequest: SendEmailChangeEmailRequest, options?: any) {
        return UserApiFp(this.configuration).sendEmailChangeEmail(sendEmailChangeEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends password reset email.
     * @param {SendPasswordResetEmailRequest} sendPasswordResetEmailRequest Payload with email.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public sendPasswordResetEmail(sendPasswordResetEmailRequest: SendPasswordResetEmailRequest, options?: any) {
        return UserApiFp(this.configuration).sendPasswordResetEmail(sendPasswordResetEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unfollows a user
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public unfollow(id: number, options?: any) {
        return UserApiFp(this.configuration).unfollow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates profile of a user
     * @param {UserProfile} userProfile Profile object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateProfile(userProfile: UserProfile, options?: any) {
        return UserApiFp(this.configuration).updateProfile(userProfile, options).then((request) => request(this.axios, this.basePath));
    }
}


