import { createSlice, PayloadAction, createAsyncThunk, } from "@reduxjs/toolkit"
import { RootState, } from "app/store"
import { REDUX_ACTION_TYPE_PREFIX, } from "app/constants"
import Me from "utils/api/models/Me"
import Scene from "utils/api/models/Scene"
import axios from "axios"
import ApiClient from "utils/api"
import { API_BASE_PATH, } from "app/env"
import { tunnelClient, tracker, } from "globalInstance"
import i18nInit from "utils/i18n"
import { getLocale, } from "utils/locale"
import history from "utils/history"

const ACTION_TYPE_PREFIX = `${REDUX_ACTION_TYPE_PREFIX}/App`

const client = axios.create({
  withCredentials: true,
})

export const notifyAppInitialized = createAsyncThunk(
  `${ACTION_TYPE_PREFIX}/notifyAppInitialized`,
  async () => {
    return new Promise<{ firstLaunch: boolean }>((resolve, reject) => {
      const intervalId = setInterval(async () => {
        if (tunnelClient.ready()) {
          clearInterval(intervalId)
          clearTimeout(timeoutId)
          try {
            const res = await tunnelClient.notifyAppInitialized()
            tracker.initialize(res.debug)
            resolve(res)
          } catch (e) {
            reject(e)
          }
        }
      }, 100)
      const timeoutId = setTimeout(() => {
        clearInterval(intervalId)
        reject(new Error("Timeout checking tunnelClient.ready()"))
      }, 5_000)
    })
  }
)

/**
 * 初期化処理
 */
export const initialize = createAsyncThunk(
  `${ACTION_TYPE_PREFIX}/initialize`,
  async () => {
    tunnelClient.setNavigatorBackCallback(() => {
      const hasHistory = history.length > 1
      if (hasHistory) {
        history.goBack()
      }
      if (window.location.pathname.startsWith("/login")) {
        history.push("/")
        return true
      }
      return hasHistory
    })
    const locale = getLocale()
    i18nInit(locale)
    // ログインユーザの情報を取得
    const apiClient = new ApiClient(API_BASE_PATH)
    let user = null
    try {
      user = await apiClient.getMe()
      await tunnelClient.notifyLogin()
    } catch (e) {
      if (axios.isAxiosError(e) && e.response && e.response.status === 401) {
        // 401エラーは未ログイン時に発生するため通知しない
        return user
      }
      throw e
    }
    return user
  }
)

/**
 * ログインをするアクション
 */
export const login = createAsyncThunk<Me, { email: string, password: string }>(
  `${ACTION_TYPE_PREFIX}/login`,
  async ({ email, password, }) => {
    // ログイン
    await client.post(`${API_BASE_PATH}/_session`, {
      email,
      password,
    })
    // ログインユーザの情報を取得
    const apiClient = new ApiClient(API_BASE_PATH)
    const user = await apiClient.getMe()
    await tunnelClient.notifyLogin()
    return user
  }
)

/**
 * ログアウトをするアクション
 */
export const logout = createAsyncThunk(
  `${ACTION_TYPE_PREFIX}/logout`,
  async () => {
    await client.delete(`${API_BASE_PATH}/_session`)
    await tunnelClient.notifyLogout()
  }
)

/**
 * サインアップをするアクション
 */
export const signup = createAsyncThunk<Me, { username: string, email: string, password: string }>(
  `${ACTION_TYPE_PREFIX}/signup`,
  async ({ username, email, password, }) => {
    await client.post(`${API_BASE_PATH}/_signup`, {
      screenName: username,
      email: email,
      password: password,
    })

    const apiClient = new ApiClient(API_BASE_PATH)
    const user = await apiClient.getMe()
    await tunnelClient.notifyLogin()
    return user
  }
)
/**
 * シーンに対していいねするアクション
 * V2のAPIができるまでの間、暫定でV1のAPIを使用しています
 */
export const doGood = createAsyncThunk(
  `${ACTION_TYPE_PREFIX}/doGood`,
  async (id: string) => {
    await client.post(`${API_BASE_PATH}/scene/${id}/good`)
    return id
  }
)

/**
 * シーンに対していいねを取り消すするアクション
 * V2のAPIができるまでの間、暫定でV1のAPIを使用しています
 */
export const undoGood = createAsyncThunk(
  `${ACTION_TYPE_PREFIX}/undoGood`,
  async (id: string) => {
    await client.delete(`${API_BASE_PATH}/scene/${id}/good`)
    return id
  }
)

/**
 * シーンをマイリストに登録するアクション
 * V2のAPIができるまでの間、暫定でV1のAPIを使用しています
 */
export const addToMylist = createAsyncThunk<Scene, string>(
  `${ACTION_TYPE_PREFIX}/addToMylist`,
  async (id: string) => {
    await client.post(`${API_BASE_PATH}/scene/mylist/${id}`)
    const apiClient = new ApiClient(API_BASE_PATH)

    // 他のreduxで使用するため詳細を取得
    const scene = await apiClient.getScene(id)
    return scene
  }
)

/**
 * マイリストに登録されているシーンを削除する
 * V2のAPIができるまでの間、暫定でV1のAPIを使用しています
 */
export const removeFromMylist = createAsyncThunk(
  `${ACTION_TYPE_PREFIX}/removeFromMylist`,
  async (id: string) => {
    await client.delete(`${API_BASE_PATH}/scene/mylist/${id}`)
    return id
  }
)

/**
 * 指定したユーザーをフォローする
 * V2のAPIができるまでの間、暫定でV1のAPIを使用しています
 */
export const follow = createAsyncThunk<number, number>(
  `${ACTION_TYPE_PREFIX}/follow`,
  async (id: number) => {
    await client.post(`${API_BASE_PATH}/brand_user/follow/${id}`)
    return id
  }
)

/**
 * 指定したユーザーをフォロー解除する
 * V2のAPIができるまでの間、暫定でV1のAPIを使用しています
 */
export const unfollow = createAsyncThunk<number, number>(
  `${ACTION_TYPE_PREFIX}/unfollow`,
  async (id: number) => {
    await client.delete(`${API_BASE_PATH}/brand_user/follow/${id}`)
    return id
  }
)

/**
 * Deletes browsing history by asking Unity to do it.
 */
export const deleteBrowingHistory = createAsyncThunk(
  `${ACTION_TYPE_PREFIX}/deleteBrowingHistory`,
  async () => {
    await tunnelClient.deleteHistory()
  }
)

export const slice = createSlice({
  name: ACTION_TYPE_PREFIX,
  initialState: {
    markerSceneId: null as (string | null),
    markerSceneTitle: null as (string | null),
    markerSceneThumbnailUrl: null as (string | null),
    showFooter: false as boolean,
    me: null as (Me | null),
    authErrorMessage: null as (string | null),
    // Unity との通信確立されたかどうか
    unityConnectionEstablishing: false,
    unityConnectionEstablished: false,
    unityConnectionEstablishError: false,
    firstLaunch: false,
    onboardingPageVisited: false,
    // 初期化関連のフラグ
    initializing: false as boolean,
    initialized: false as boolean,
    initializeError: false as boolean,
    // ユーザの操作を受け付けない処理を実行中の場合にtrue
    intaractionRestricted: false as boolean,
  },
  reducers: {
    showMarker: (state, action: PayloadAction<{ id: string, title: string, thumbnailUrl: string}>) => {
      const { id, title, thumbnailUrl, } = action.payload
      return {
        ...state,
        markerSceneId: id,
        markerSceneTitle: title,
        markerSceneThumbnailUrl: thumbnailUrl,
      }
    },
    hideMarker: (state) => {
      return {
        ...state,
        markerSceneId: null,
        markerSceneThumbnailUrl: null,
      }
    },
    markOnboardingPageVisited: (state) => {
      state.onboardingPageVisited = true
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        notifyAppInitialized.pending,
        (state) => {
          state.unityConnectionEstablishing = true
        })
      .addCase(
        notifyAppInitialized.fulfilled,
        (state, action) => {
          state.unityConnectionEstablishing = true
          state.unityConnectionEstablished = true
          state.unityConnectionEstablishError = false
          state.firstLaunch = action.payload.firstLaunch
        })
      .addCase(
        notifyAppInitialized.rejected,
        (state) => {
          state.unityConnectionEstablishing = true
          state.unityConnectionEstablished = false
          state.unityConnectionEstablishError = true
        })
      .addCase(
        initialize.pending,
        (state) => {
          state.initializing = true
          state.initialized = false
          state.intaractionRestricted = true
        })
      .addCase(
        initialize.rejected,
        (state) => {
          state.initializing = false
          state.initializeError = true
          state.intaractionRestricted = false
        })
      .addCase(
        initialize.fulfilled,
        (state, action) => {
          state.initializing = false
          state.initialized = true
          state.me = action.payload
          state.intaractionRestricted = false
        })
      .addCase(
        login.pending,
        (state) => {
          state.authErrorMessage = null
          state.me = null
          state.intaractionRestricted = true
        })
      .addCase(
        login.rejected,
        (state) => {
          state.authErrorMessage = "login failed"
          state.intaractionRestricted = false
        })
      .addCase(
        login.fulfilled,
        (state, action) => {
          state.me = action.payload
          state.intaractionRestricted = false
        })
      .addCase(
        logout.pending,
        (state) => {
          state.authErrorMessage = null
          state.intaractionRestricted = true
        })
      .addCase(
        logout.rejected,
        (state) => {
          state.authErrorMessage = "logout failed"
          state.intaractionRestricted = false
        })
      .addCase(
        logout.fulfilled,
        (state) => {
          state.me = null
          state.intaractionRestricted = false
        })
      .addCase(
        signup.pending,
        (state) => {
          state.me = null
          state.authErrorMessage = null
          state.intaractionRestricted = true
        })
      .addCase(
        signup.rejected,
        (state) => {
          state.authErrorMessage = "signup failed"
          state.intaractionRestricted = false
        })
      .addCase(
        signup.fulfilled,
        (state, action) => {
          state.me = action.payload
          state.intaractionRestricted = false
        })
  },
})

export const selectState = (state: RootState) => state.app

export default slice.reducer
